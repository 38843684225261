import React from 'react';
import { PageLayout } from '../components/pageLayout';
import { graphql } from 'gatsby';
import { SEO } from '../components/seo';
import SliceZone from '../components/SliceZone';
import { FeatureImageHeader } from '../components/FeatureImageHeader';
import { Flex, Box, Text, Icon, Link } from '@chakra-ui/core';
import { format } from 'date-fns';
import { Container } from '../components/Container';
import { SocialLinks } from '../components/SocialLinks';
import linkResolver from '../utils/linkResolver';
import { StyledRichText } from '../components/StyledRichText';

const MarketPost = ({ data }) => {
	const prismicContent = data.prismic.allMarket_reports.edges[0];
	if (!prismicContent) return null;
	const document = prismicContent.node;

	const capitalizeFirstLetter = (input) => {
		return input[0].toUpperCase() + input.slice(1);
	};
	console.log(prismicContent);

	let date = document._meta.lastPublicationDate;
	// date = new Date(date)

	let formattedDate = format(new Date(date), 'dd MMMM yyyy');
	// "" + date.getDate() + " " +

	return (
		<PageLayout>
			<SEO title={capitalizeFirstLetter(document._meta.uid)} />
			<FeatureImageHeader
				title={document.title[0]!.text}
				featureImageUrl={document.cover_photo!.url}
				bodyText={document.sub_title[0]!.text}
			/>
			<Container my={8}>
				<Flex mt={0} flexDir={{ base: 'column', md: 'row' }}>
					<Box w={3 / 12} pb={6}>
						<Text>{formattedDate}</Text>
						<Text>
							By <strong>{document.author}</strong>
						</Text>
					</Box>
					<Box w={6 / 12}>
						<StyledRichText
							render={document.body}
							linkResolver={linkResolver}
						/>
						{/* <SliceZone sliceZone={document.body} /> */}
					</Box>
					<SocialLinks
						title={document.title[0]!.text}
						w={3 / 12}
						pl={{ base: 0, md: 8 }}
						pt={{ base: 8, md: 0 }}
						d="inline"
					/>
				</Flex>
			</Container>
		</PageLayout>
	);
};

export const query = graphql`
	query reportQuery($uid: String) {
		prismic {
			allMarket_reports(uid: $uid) {
				edges {
					node {
						title
						cover_photo
						sub_title
						author
						body
						_linkType
						_meta {
							type
							uid
							lastPublicationDate
						}
						__typename
					}
				}
			}
		}
	}
`;

export default MarketPost;
